<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Product</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="product-form">
          <v-text-field
            label="Name *"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-text-field
            label="Slug *"
            v-model="slug"
            type="text"
            outlined
            dense
            background-color="white"
            :error="errors.hasOwnProperty('slug')"
            :error-messages="errors['slug']"
          ></v-text-field>
          <v-text-field
            label="Sub Title"
            v-model="fields.sub_title"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('sub_title')"
            :error-messages="errors['sub_title']"
          ></v-text-field>
          <v-textarea
            label="Short Description *"
            v-model="fields.short_description"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('short_description')"
            :error-messages="errors['short_description']"
          ></v-textarea>
          <v-textarea
            label="Description *"
            v-model="fields.description"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('description')"
            :error-messages="errors['description']"
          ></v-textarea>
          <v-file-input
            v-model="fields.thumbnail"
            accept="image/png, image/jpeg"
            placeholder="Choose a Thumbnail Image"
            prepend-icon="mdi-camera"
            label="Thumbnail *"
            outlined
            background-color="white"
            @change="thumbnailHasChanged()"
            :error="errors.hasOwnProperty('thumbnail')"
            :error-messages="errors['thumbnail']"
          ></v-file-input>
          <v-file-input
            v-model="fields.seed_illustration"
            accept="image/png, image/jpeg"
            placeholder="Choose a Seed Illustration Image"
            prepend-icon="mdi-camera"
            label="Seed Illustration"
            outlined
            background-color="white"
            @change="seedIllustrationHasChanged()"
            :error="errors.hasOwnProperty('seed_illustration')"
            :error-messages="errors['seed_illustration']"
          ></v-file-input>
          <v-select
            label="Categories"
            v-model="fields.categories"
            :items="categories"
            item-text="name"
            item-value="id"
            outlined
            multiple
            chips
            background-color="white"
            :error="errors.hasOwnProperty('categories')"
            :error-messages="errors['categories']"
          ></v-select>
          <v-select
            label="Species"
            v-model="fields.species"
            :items="species"
            item-text="name"
            item-value="id"
            outlined
            multiple
            chips
            background-color="white"
            :error="errors.hasOwnProperty('species')"
            :error-messages="errors['species']"
          ></v-select>
          <v-select
            label="Testimonial"
            v-model="fields.testimonial_id"
            :items="testimonials"
            item-text="by"
            item-value="id"
            outlined
            background-color="white"
            clearable
            :error="errors.hasOwnProperty('testimonial_id')"
            :error-messages="errors['testimonial_id']"
          ></v-select>
          <v-switch
            label="Is this an Organic Product?"
            v-model="fields.is_organic"
            color="green"
            class="mt-1 mb-4"
            inset
            :error="errors.hasOwnProperty('is_organic')"
            :error-messages="errors['is_organic']"
          ></v-switch>
          <v-switch
            label="Can this product be used in Scotland?"
            v-model="fields.is_north"
            color="green"
            class="mt-1 mb-4"
            inset
            :error="errors.hasOwnProperty('is_north')"
            :error-messages="errors['is_north']"
          ></v-switch>
          <v-switch
            label="Can this product be used in England?"
            v-model="fields.is_south"
            color="green"
            class="mt-1 mb-4"
            inset
            :error="errors.hasOwnProperty('is_south')"
            :error-messages="errors['is_south']"
          ></v-switch>
          <v-card elevation="2" class="mb-8">
            <v-card-subtitle>Prices</v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text class="pb-0">
              <v-text-field
                label="Price Kg *"
                v-model="fields.price_kg"
                type="text"
                outlined
                prefix="£"
                :error="errors.hasOwnProperty('price_kg')"
                :error-messages="errors['price_kg']"
              ></v-text-field>
              <v-text-field
                label="Price Ac"
                v-model="fields.price_ac"
                type="text"
                outlined
                prefix="£"
                :error="errors.hasOwnProperty('price_ac')"
                :error-messages="errors['price_ac']"
              ></v-text-field>
              <v-text-field
                label="Price Ha"
                v-model="fields.price_ha"
                type="text"
                outlined
                prefix="£"
                :error="errors.hasOwnProperty('price_ha')"
                :error-messages="errors['price_ha']"
              ></v-text-field>
              <v-switch
                label="Allow Removal of Clover?"
                v-model="fields.contains_clover"
                color="green"
                class="mt-1 mb-4"
                inset
                :error="errors.hasOwnProperty('contains_clover')"
                :error-messages="errors['contains_clover']"
              ></v-switch>
              <v-switch
                label="Include VAT?"
                v-model="fields.include_vat"
                color="green"
                class="mt-1 mb-4"
                inset
                :error="errors.hasOwnProperty('include_vat')"
                :error-messages="errors['include_vat']"
              ></v-switch>

              <v-switch
                label="Out of Stock"
                v-model="fields.is_out_of_stock"
                color="green"
                class="mt-1 mb-4"
                inset
                :error="errors.hasOwnProperty('is_out_of_stock')"
                :error-messages="errors['is_out_of_stock']"
              ></v-switch>
            </v-card-text>
          </v-card>
          <v-select
            label="Product Type *"
            :items="types"
            item-value="value"
            item-text="label"
            v-model="fields.type"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('type')"
            :error-messages="errors['type']"
          ></v-select>
          <MixtureDetailsForm
            ref="mixtureDetails"
            :product="product"
            v-if="fields.type === 'mixtures'"
          />
          <HerbLegumeDetailsForm
            ref="herbLegumeDetails"
            :product="product"
            v-if="fields.type === 'herb_legume'"
          />
          <FlowerDetailsForm
            ref="flowerDetails"
            :product="product"
            v-if="fields.type === 'flower'"
          />
          <AgriEnviroDetailsForm
            ref="agriEnviroDetails"
            :product="product"
            v-if="fields.type === 'agri_enviro'"
          />
          <CoverMixtureDetailsForm
            ref="coverMixtureDetails"
            :product="product"
            v-if="fields.type === 'cover_mixture'"
          />
          <StraightDetailsForm
            ref="straightDetails"
            :product="product"
            v-if="fields.type === 'straight'"
          />
        </v-form>
        <v-overlay absolute v-if="productLoading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          :disabled="productLoading"
          form="product-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MixtureDetailsForm from "./MixtureDetailsForm";
import StraightDetailsForm from "./StraightDetailsForm";
import HerbLegumeDetailsForm from "./HerbLegumeDetailsForm";
import FlowerDetailsForm from "./FlowerDetailsForm";
import AgriEnviroDetailsForm from "./AgriEnviroDetailsForm";
import CoverMixtureDetailsForm from "./CoverMixtureDetailsForm";

export default {
  components: {
    MixtureDetailsForm,
    HerbLegumeDetailsForm,
    FlowerDetailsForm,
    AgriEnviroDetailsForm,
    CoverMixtureDetailsForm,
    StraightDetailsForm,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      productLoading: false,
      isEditing: false,
      product: {},
      fields: {
        name: null,
        slug: null,
        sub_title: null,
        short_description: null,
        description: null,
        categories: [],
        species: [],
        testimonial_id: null,
        thumbnail: null,
        thumbnail_has_changed: false,
        seed_illustration: null,
        seed_illustration_has_changed: false,
        is_organic: false,
        is_north: false,
        is_south: false,
        price_ac: null,
        price_ha: null,
        price_kg: null,
        contains_clover: false,
        include_vat: false,
        is_out_of_stock: false,
        benefits: null,
        type: null,
      },
      errors: {},
      types: [
        { value: "mixtures", label: "Mixture" },
        { value: "straight", label: "Straight" },
        { value: "herb_legume", label: "Herbs/Legume" },
        { value: "flower", label: "Flower" },
        { value: "cover_mixture", label: "Cover Mixture" },
        { value: "agri_enviro", label: "Agri/Enviro" },
      ],
    };
  },

  computed: {
    slug: {
      get() {
        if (this.isEditing) {
          return this.fields.slug;
        }

        let slug = this.fields.name;

        if (slug === "" || slug === null) {
          return slug;
        }

        slug = slug.trim();
        slug = slug.replaceAll("&", "and");
        slug = slug.replaceAll(",", "");
        slug = slug.replaceAll(/[^a-zA-Z ]/g, "");
        slug = slug.replaceAll(" ", "-");
        slug = slug.toLowerCase();
        if (slug.substr(-1) === "-") {
          slug = slug.substr(0, slug.length - 1);
        }

        return slug;
      },
      set(value) {
        return value;
      },
    },

    categories() {
      return this.$store.state.watson.products["categories"].filter((c) => {
        return c.is_parent === false;
      });
    },

    species() {
      return this.$store.state.watson.species["species"];
    },

    testimonials() {
      return this.$store.state.watson.testimonials["testimonials"];
    },
  },

  watch: {
    slug(value) {
      this.fields.slug = value;
    },
  },

  methods: {
    openForm(productId = null) {
      if (productId !== null) {
        this.isEditing = true;
        this.productLoading = true;

        this.$store
          .dispatch("watson/products/loadProduct", {
            appId: this.$route.params.id,
            productId,
          })
          .then((product) => {
            this.product = product;
            this.fields.name = product.name;
            this.fields.slug = product.slug;
            this.fields.sub_title = product.sub_title;
            this.fields.short_description = product.short_description;
            this.fields.description = product.description;
            this.fields.thumbnail = product.thumbnail
              ? new File([product.thumbnail], product.thumbnail)
              : null;
            this.fields.seed_illustration = product.seed_illustration
              ? new File([product.seed_illustration], product.seed_illustration)
              : null;
            this.fields.categories = product.categories.map((c) => c.id);
            this.fields.species = product.species.map((s) => s.id);
            this.fields.testimonial_id = product.testimonial_id;
            this.fields.is_organic = product.is_organic;
            this.fields.is_north = product.is_north;
            this.fields.is_south = product.is_south;
            this.fields.price_ac = product.price_ac;
            this.fields.price_ha = product.price_ha;
            this.fields.price_kg = product.price_kg;
            this.fields.contains_clover = product.contains_clover;
            this.fields.include_vat = product.include_vat;
            this.fields.is_out_of_stock = product.is_out_of_stock;
            this.fields.type = product.type;
            this.productLoading = false;
          });
      }

      this.dialog = true;
    },

    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.product = {};
      this.errors = {};
      this.fields.name = null;
      this.fields.slug = null;
      this.fields.sub_title = null;
      this.fields.short_description = null;
      this.fields.description = null;
      this.fields.categories = [];
      this.fields.species = [];
      this.fields.testimonial_id = null;
      this.fields.thumbnail = null;
      this.fields.thumbnail_has_changed = false;
      this.fields.seed_illustration = null;
      this.fields.seed_illustration_has_changed = false;
      this.fields.is_organic = false;
      this.fields.is_north = false;
      this.fields.is_south = false;
      this.fields.price_ac = null;
      this.fields.price_ha = null;
      this.fields.price_kg = null;
      this.fields.contains_clover = false;
      this.fields.include_vat = false;
      this.fields.is_out_of_stock = false;
      this.fields.type = null;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let fields = ((type) => {
        switch (type) {
          case "mixtures":
            return this.$refs.mixtureDetails.saveForm();
          case "flower":
            return this.$refs.flowerDetails.saveForm();
          case "cover_mixture":
            return this.$refs.coverMixtureDetails.saveForm();
          case "agri_enviro":
            return this.$refs.agriEnviroDetails.saveForm();
          case "herb_legume":
            return this.$refs.herbLegumeDetails.saveForm();
          case "straight":
            return this.$refs.straightDetails.saveForm();
          default:
            return null;
        }
      })(this.fields.type);

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: {
          ...fields,
          ...this.fields,
        },
      };

      if (this.isEditing) {
        payload.productId = this.product.id;
      }

      this.$store
        .dispatch("watson/products/saveProduct", payload)
        .then(() => {
          this.resetForm();

          if (payload.fields.type === "mixtures") {
            this.$refs.mixtureDetails.resetForm();
            this.$refs.mixtureDetails.setErrors();
          } else if (payload.fields.type === "flower") {
            this.$refs.flowerDetails.resetForm();
            this.$refs.flowerDetails.setErrors();
          } else if (payload.fields.type === "cover_mixture") {
            this.$refs.coverMixtureDetails.resetForm();
            this.$refs.coverMixtureDetails.setErrors();
          } else if (payload.fields.type === "agri_enviro") {
            this.$refs.agriEnviroDetails.resetForm();
            this.$refs.agriEnviroDetails.setErrors();
          } else if (payload.fields.type === "herb_legume") {
            this.$refs.herbLegumeDetails.resetForm();
            this.$refs.herbLegumeDetails.setErrors();
          } else if (payload.fields.type === "straight") {
            this.$refs.straightDetails.resetForm();
            this.$refs.straightDetails.setErrors();
          }
        })
        .catch((err) => {
          this.errors = err.response.data.errors;

          if (payload.fields.type === "mixtures") {
            this.$refs.mixtureDetails.setErrors(err.response.data.errors);
          } else if (payload.fields.type === "flower") {
            this.$refs.flowerDetails.setErrors(err.response.data.errors);
          } else if (payload.fields.type === "cover_mixture") {
            this.$refs.coverMixtureDetails.setErrors(err.response.data.errors);
          } else if (payload.fields.type === "agri_enviro") {
            this.$refs.agriEnviroDetails.setErrors(err.response.data.errors);
          } else if (payload.fields.type === "herb_legume") {
            this.$refs.herbLegumeDetails.setErrors(err.response.data.errors);
          } else if (payload.fields.type === "straight") {
            this.$refs.straightDetails.setErrors(err.response.data.errors);
          }

          this.loading = false;
        });
    },

    thumbnailHasChanged() {
      this.fields.thumbnail_has_changed = true;
    },

    seedIllustrationHasChanged() {
      this.fields.seed_illustration_has_changed = true;
    },

    clearTestimonialId() {
      this.fields.testimonial_id = null;
    },
  },
};
</script>
