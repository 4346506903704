<template>
  <div>
    <v-text-field
      label="Duration *"
      v-model="fields.duration"
      type="text"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('duration')"
      :error-messages="errors['duration']"
    ></v-text-field>
    <v-text-field
      label="Grazing Suitability *"
      v-model="fields.grazing_suitability"
      type="number"
      min="1"
      max="5"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('grazing_suitability')"
      :error-messages="errors['grazing_suitability']"
    ></v-text-field>
    <v-text-field
      label="Cutting Suitability *"
      v-model="fields.cutting_suitability"
      type="number"
      min="1"
      max="5"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('cutting_suitability')"
      :error-messages="errors['cutting_suitability']"
    ></v-text-field>
    <v-text-field
      label="Tetraploid *"
      v-model="fields.tetraploid"
      type="number"
      min="1"
      max="100"
      outlined
      background-color="white"
      suffix="%"
      :error="errors.hasOwnProperty('tetraploid')"
      :error-messages="errors['tetraploid']"
    ></v-text-field>
    <v-text-field
      label="Sowing Rate *"
      v-model="fields.sowing_rate"
      type="number"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('sowing_rate')"
      :error-messages="errors['sowing_rate']"
    ></v-text-field>
    <v-switch
      label="Mainly Cutting *"
      v-model="fields.mainly_cutting"
      color="green"
      class="mt-1 mb-4"
      inset
    ></v-switch>
    <v-switch
      label="Dual Purpose *"
      v-model="fields.dual_purpose"
      color="green"
      class="mt-1 mb-4"
      inset
    ></v-switch>
    <v-switch
      label="Intensive Grazing *"
      v-model="fields.intensive_grazing"
      color="green"
      class="mt-1 mb-4"
      inset
    ></v-switch>
    <v-switch
      label="Extensive Grazing *"
      v-model="fields.extensive_grazing"
      color="green"
      class="mt-1 mb-4"
      inset
    ></v-switch>
    <v-card>
      <v-data-table
        :headers="varietiesTable.headers"
        :items="varietiesWithIndex"
        :disable-pagination="true"
        :hide-default-footer="true"
        no-data-text="There are no varieties"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title class="text-subtitle-1">Varieties</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="accent" small @click="openVarietyDialog()"
              >Add Row</v-btn
            >
          </v-toolbar>
          <v-alert
            type="error"
            dense
            col="4"
            rounded="0"
            class="mb-0"
            v-if="errors.hasOwnProperty('varieties')"
            >{{ errors["varieties"].join(", ") }}</v-alert
          >
          <v-divider></v-divider>
        </template>
        <template v-slot:item.title="{ item }">
          <div style="white-space: pre-line" v-html="item.title"></div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                text
                icon
                color="blue"
                v-on="on"
                class="mr-2"
                @click="openVarietyDialog(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                text
                icon
                color="red"
                v-on="on"
                @click="deleteVariety(item.index)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-card class="mt-5">
      <v-card-title>Addons</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-select
          label="Addons"
          v-model="selectedAddons"
          :items="addons"
          item-value="id"
          item-text="name"
          multiple
          outlined
          :error="errors.hasOwnProperty('addons')"
          :error-messages="errors['addons']"
        ></v-select>
        <v-text-field
          v-for="(addon, index) in fields.addons"
          :key="addon.id"
          :label="getAddonLabel(addon.id)"
          v-model="fields.addons[index].sowing_ratio"
          outlined
        ></v-text-field>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="varietiesDialog.open"
      max-width="400"
      @click:outside="resetVarietyDialog()"
    >
      <v-card>
        <v-card-title class="headline"
          >{{
            varietiesDialog.isEditing ? "Edit" : "New"
          }}
          Variety</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="pt-6 pb-0">
          <v-textarea
            label="Variety *"
            v-model="varietiesDialog.fields.title"
            outlined
          ></v-textarea>
          <v-text-field
            label="Type *"
            v-model="varietiesDialog.fields.type"
            type="text"
            outlined
          ></v-text-field>
          <v-text-field
            label="Percentage *"
            v-model="varietiesDialog.fields.percent"
            outlined
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetVarietyDialog()"
            >Close</v-btn
          >
          <v-btn color="accent" text type="submit" @click="saveVarietyDialog()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      required: true,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
  },

  data() {
    return {
      fields: {
        grazing_suitability: null,
        cutting_suitability: null,
        tetraploid: null,
        varieties: [],
        duration: null,
        mainly_cutting: false,
        dual_purpose: false,
        intensive_grazing: false,
        extensive_grazing: false,
        sowing_rate: null,
        addons: [],
      },
      varietiesTable: {
        headers: [
          { text: "Variety", value: "title", sortable: false },
          { text: "Type", value: "type", sortable: false },
          { text: "Percent", value: "percent", sortable: false },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "right",
          },
        ],
        dialog: {
          open: false,
          loading: false,
        },
      },
      varietiesDialog: {
        open: false,
        isEditing: false,
        variety: {},
        fields: {
          title: null,
          type: null,
          percent: null,
        },
      },
      errors: {},
    };
  },

  mounted() {
    if (this.product !== null) {
      if (this.product.type == "mixtures") {
        this.fields.grazing_suitability =
          this.product.details.grazing_suitability;
        this.fields.cutting_suitability =
          this.product.details.cutting_suitability;
        this.fields.tetraploid = this.product.details.tetraploid;
        this.fields.varieties = this.product.details.varieties;
        this.fields.duration = this.product.details.duration;
        this.fields.mainly_cutting = this.product.details.mainly_cutting;
        this.fields.dual_purpose = this.product.details.dual_purpose;
        this.fields.intensive_grazing = this.product.details.intensive_grazing;
        this.fields.extensive_grazing = this.product.details.extensive_grazing;
        this.fields.sowing_rate = this.product.details.sowing_rate;
      }

      if (this.product.addons.length > 0) {
        let addons = [];
        this.product.addons.forEach((addon) => {
          addons.push({
            id: addon.id,
            sowing_ratio: addon.pivot.sowing_ratio,
          });
        });
        this.fields.addons = addons;
      }
    }
  },

  computed: {
    varietiesWithIndex() {
      return this.fields.varieties.map((variety, index) => ({
        ...variety,
        index,
      }));
    },

    addons() {
      return this.$store.state.watson.products["addons"];
    },

    selectedAddons: {
      get() {
        return this.fields.addons.map((a) => a["id"]);
      },

      set(addons) {
        let setAddons = this.fields.addons;

        addons.forEach((id) => {
          let found = false;
          setAddons.forEach((a) => {
            if (a.id === id) {
              found = true;
            }
          });

          if (found === false) {
            setAddons.push({
              id,
              sowing_ratio: null,
            });
          }
        });

        this.fields.addons = setAddons.filter((a) => {
          return addons.includes(a.id);
        });
      },
    },
  },

  methods: {
    saveForm() {
      return this.fields;
    },

    setErrors(errors = {}) {
      this.errors = errors;
    },

    resetForm() {
      this.fields.addons = [];
    },

    openVarietyDialog(variety = null) {
      if (variety !== null) {
        this.varietiesDialog.isEditing = true;
        this.varietiesDialog.variety = variety;
        this.varietiesDialog.fields.title = variety.title;
        this.varietiesDialog.fields.type = variety.type;
        this.varietiesDialog.fields.percent = variety.percent;
      }

      this.varietiesDialog.open = true;
    },

    resetVarietyDialog() {
      this.varietiesDialog.open = false;
      this.varietiesDialog.isEditing = false;
      this.varietiesDialog.variety = {};
      this.varietiesDialog.fields.title = null;
      this.varietiesDialog.fields.type = null;
      this.varietiesDialog.fields.percent = null;
    },

    saveVarietyDialog() {
      if (this.varietiesDialog.isEditing) {
        this.fields.varieties[this.varietiesDialog.variety.index].title =
          this.varietiesDialog.fields.title;
        this.fields.varieties[this.varietiesDialog.variety.index].type =
          this.varietiesDialog.fields.type;
        this.fields.varieties[this.varietiesDialog.variety.index].percent =
          this.varietiesDialog.fields.percent;
      } else {
        this.fields.varieties.push({
          title: this.varietiesDialog.fields.title,
          type: this.varietiesDialog.fields.type,
          percent: this.varietiesDialog.fields.percent,
        });
      }

      this.resetVarietyDialog();
    },

    deleteVariety(index) {
      this.fields.varieties.splice(index, 1);
    },

    getAddonLabel(addonId = null) {
      if (addonId === null) {
        return "";
      }

      const addonIndex = this.addons.findIndex((a) => a.id === addonId);
      const addon = this.addons[addonIndex];
      return "Sowing Ratio for " + addon.name + " *";
    },
  },
};
</script>
