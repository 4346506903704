<template>
  <div>
    <v-card>
      <v-card-subtitle>Details</v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>
        <v-alert type="error" dense v-if="errors.hasOwnProperty('details')">{{
          errors["details"].join(", ")
        }}</v-alert>
        <v-card v-for="(table, i1) in detailTables2" :key="i1" class="mb-6">
          <v-simple-table v-if="table.items.length">
            <template v-slot:default>
              <thead></thead>
              <tbody v-for="(item, i2) in table.items" :key="i2">
                <tr>
                  <th v-for="(item3, i4) in item.headings" :key="i4">
                    <div>
                      {{ item3 }}

                      <v-btn
                        color="primary"
                        x-small
                        text
                        icon
                        @click="openHeadingDialog(table, i1, i4, item3)"
                      >
                        <v-icon x-small>mdi-pencil</v-icon></v-btn
                      >
                    </div>
                  </th>
                  <th class="text-right">Actions</th>
                </tr>

                <tr v-for="(item2, i3) in item.rows" :key="i3">
                  <td v-for="(item3, i4) in item.headings" :key="i4">
                    <div>{{ item2[i4] }}</div>
                  </td>

                  <td class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          x-small
                          text
                          icon
                          color="blue"
                          v-on="on"
                          class="mr-2"
                          @click="openDetailsDialog(table, i1, i3, item)"
                        >
                          <v-icon x-small>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          x-small
                          text
                          icon
                          color="red"
                          v-on="on"
                          @click="deleteDetail(i1, i3)"
                        >
                          <v-icon x-small>mdi-archive</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-text v-else>No Data In This Table</v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="primary"
              depressed
              small
              @click="openHeadingDialog(table, i1)"
              >Add New Heading</v-btn
            >

            <v-btn
              color="primary"
              depressed
              small
              @click="openDetailsDialog(table, i1)"
              >Add New Row</v-btn
            >
          </v-card-actions>
        </v-card>
        <div>
          <v-btn color="accent" depressed small @click="addTable()"
            >Add New Table</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
    <v-card class="mt-5">
      <v-card-title>Addons</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-select
          label="Addons"
          v-model="selectedAddons"
          :items="addons"
          item-value="id"
          item-text="name"
          multiple
          outlined
          :error="errors.hasOwnProperty('addons')"
          :error-messages="errors['addons']"
        ></v-select>
        <v-text-field
          v-for="(addon, index) in fields.addons"
          :key="addon.id"
          :label="getAddonLabel(addon.id)"
          v-model="fields.addons[index].sowing_ratio"
          outlined
        ></v-text-field>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="headingDialog.open"
      max-width="400"
      @click:outside="resetDetailsDialog()"
    >
      <v-card>
        <v-card-title class="headline"
          >{{ headingDialog.isEditing ? "Edit" : "New" }} Headings</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="pt-6 pb-0">
          <v-text-field
            label="Heading"
            v-model="headingDialog.fields.content"
            type="text"
            outlined
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetHeadingDialog()"
            >Close</v-btn
          >
          <v-btn color="accent" text type="submit" @click="saveHeadingDialog()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="detailsDialog.open"
      max-width="400"
      @click:outside="resetDetailsDialog()"
    >
      <v-card>
        <v-card-title class="headline"
          >{{ detailsDialog.isEditing ? "Edit" : "New" }} Variety</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="pt-6 pb-0">
          <v-text-field
            v-for="(heading, index) in detailsDialog.fields.headingsCount"
            :key="`${index}-time-slot`"
            :label="heading"
            v-model="detailsDialog.fields.content[index]"
            type="text"
            outlined
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetDetailsDialog()"
            >Close</v-btn
          >
          <v-btn color="accent" text type="submit" @click="saveDetailsDialog()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      required: true,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
  },

  data() {
    return {
      fields: {
        details: [],
        sowing_rate: 0,
        details2: null,
        addons: [],
      },
      newTables: [],
      headings: [],
      tables: [],
      headingDialog: {
        open: false,
        isEditing: false,
        tableIndex: null,
        fields: {
          content: [],
        },
      },

      detailsDialog: {
        open: false,
        isEditing: false,
        detail: {},
        table: {},
        tableIndex: null,
        fields: {
          headingsCount: [],
          title: null,
          content: [],
        },
      },
      errors: {},
    };
  },

  mounted() {
    if (this.product !== null) {
      if (this.product.type == "flower") {
        this.fields.details = this.product.details.details;
        this.fields.details2 = this.product.details.details;
      }

      if (this.product.addons.length > 0) {
        let addons = [];
        this.product.addons.forEach((addon) => {
          addons.push({
            id: addon.id,
            sowing_ratio: addon.pivot.sowing_ratio,
          });
        });
        this.fields.addons = addons;
      }
    }
  },

  computed: {
    detailTables2() {
      let tables = [];

      if (this.fields.details2 !== null) {
        for (let index in this.fields.details2.tables) {
          let detail = this.fields.details2;

          let rows = detail.tables[index].rows;
          let headings = detail.tables[index].headings;

          tables[index] = {
            items: [],
          };

          tables[index].items.push({
            rows: rows,
            headings: headings,
          });
        }
      }

      return tables;
    },

    addons() {
      return this.$store.state.watson.products["addons"];
    },

    selectedAddons: {
      get() {
        return this.fields.addons.map((a) => a["id"]);
      },

      set(addons) {
        let setAddons = this.fields.addons;

        addons.forEach((id) => {
          let found = false;
          setAddons.forEach((a) => {
            if (a.id === id) {
              found = true;
            }
          });

          if (found === false) {
            setAddons.push({
              id,
              sowing_ratio: null,
            });
          }
        });

        this.fields.addons = setAddons.filter((a) => {
          return addons.includes(a.id);
        });
      },
    },
  },

  methods: {
    saveForm() {
      return this.fields;
    },

    setErrors(errors = {}) {
      this.errors = errors;
    },

    resetForm() {
      this.fields.details2 = null;
    },

    addTable() {
      if (this.fields.details2 == null) {
        this.fields.details2 = {
          type: "flower",
          tables: [{ headings: ["Type", "%"], rows: [] }],
        };
      } else {
        this.fields.details2.tables.push({ headings: ["Type", "%"], rows: [] });
      }
    },

    deleteDetail(index, indexRow) {
      this.fields.details2.tables[index].rows.splice(indexRow, 1);
    },

    openDetailsDialog(table, tableIndex, rowIndex = null, detail = null) {
      if (detail !== null) {
        this.detailsDialog.isEditing = true;
        this.detailsDialog.detail = detail;

        this.detailsDialog.rowIndex = rowIndex;

        for (let indexHeading in this.fields.details2.tables[tableIndex]
          .headings) {
          this.detailsDialog.fields.content[indexHeading] =
            detail.rows[rowIndex][indexHeading];
        }
      }

      this.detailsDialog.fields.headingsCount =
        this.fields.details2.tables[tableIndex].headings;

      this.detailsDialog.table = table;
      this.detailsDialog.tableIndex = tableIndex;

      this.detailsDialog.open = true;
    },

    openHeadingDialog(table, tableIndex, headingIndex = null, heading = null) {
      if (headingIndex !== null) {
        this.headingDialog.isEditing = true;
        this.headingDialog.detail = heading;

        this.headingDialog.fields.content = heading;
        this.headingDialog.headingIndex = headingIndex;
      }

      this.headingDialog.table = table;
      this.headingDialog.tableIndex = tableIndex;
      this.headingDialog.open = true;
    },

    resetHeadingDialog() {
      this.headingDialog.open = false;
      this.headingDialog.isEditing = false;
      this.headingDialog.detail = {};
      this.headingDialog.table = {};
      this.headingDialog.tableIndex = null;
      this.headingDialog.fields.content = [];
    },

    resetDetailsDialog() {
      this.detailsDialog.open = false;
      this.detailsDialog.isEditing = false;
      this.detailsDialog.detail = {};
      this.detailsDialog.table = {};
      this.detailsDialog.tableIndex = null;
      this.detailsDialog.fields.content = [];
    },

    saveHeadingDialog() {
      const headingDialog = this.headingDialog;

      if (this.headingDialog.isEditing) {
        const headingIndex = headingDialog.headingIndex;

        this.fields.details2.tables[headingDialog.tableIndex].headings.splice(
          headingIndex,
          1,
          headingDialog.fields.content
        );
      } else {
        this.fields.details2.tables[headingDialog.tableIndex].headings.push(
          headingDialog.fields.content
        );
      }

      this.resetHeadingDialog();
    },

    saveDetailsDialog() {
      const detailsDialog = this.detailsDialog;
      const index = detailsDialog.tableIndex;

      if (this.detailsDialog.isEditing) {
        const rowIndex = detailsDialog.rowIndex;

        this.fields.details2.tables[index].rows.splice(
          rowIndex,
          1,
          detailsDialog.fields.content
        );
      } else {
        this.fields.details2.tables[index].rows.push(
          detailsDialog.fields.content
        );
      }

      this.resetDetailsDialog();
    },
    getAddonLabel(addonId = null) {
      if (addonId === null) {
        return "";
      }

      const addonIndex = this.addons.findIndex((a) => a.id === addonId);
      const addon = this.addons[addonIndex];
      return "Sowing Ratio for " + addon.name + " *";
    },
  },
};
</script>
